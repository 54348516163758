.company-summary-card{
    padding: 0px;
    margin: .5rem;
    border-radius: 24px;
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / .1), 0 8px 10px -6px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);

}
.payment-card{
    background-color: rgb(190, 224, 254,0.5);
}

.order-card{
    background-color: rgb(190, 224, 254,0.5);
    // background-color: #C6FACC;
}

.pastduepayment-card{
    background-color: rgb(255, 200, 221,.5);
}

.company-summary-card:hover{
    box-shadow: 0 9px 10px rgb(0 0 0 / 5%), 0 10px 10px rgb(0 0 0 / 5%);
    cursor: pointer;
}

.info-button{
    border: none;
    border-radius: 8px;
    padding: 0 0 0 4px;
    background-color: rgb(234, 250, 231,0);
    img {
      margin-right: 0 !important;
    }

}
@import "_mixins.scss";

.table-quantity {
  &:after {
    content: "x";
    position: relative;
    left: 50px;
    text-align: right;
  }
}

.table-price {
  &:after {
    after {
      content: "=";
      position: relative;
      left: 50px;
      text-align: right;
    }
  }
}

.pagination__dot {
  position: relative;
  width: 8px;
  height: 8px;
  background: #8a92b0;
  border-radius: 100px;
  display: inline-block;
  cursor: pointer;
  margin: 0 4px;
  transition: 0.3s;

  &:hover {
    transition: 0.3s;
    border-color: #262b40;
    background: #262b40;

    &:before {
      top: -60px;
      opacity: 1;
    }

    &:after {
      top: -18px;
      opacity: 1;
    }
  }
}

.pagination__dot--active {
  background: #262b40;
  border: 2px solid #262b40;
}

.distro-table .table > tbody > tr > td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.distro-table .table > tbody > tr > td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table-responsive {
  background: white;
  top: 0;

  thead {
    z-index: 9;

    // tr {
    //   th {
    //     &:first-child {
    //       padding-left: 0rem;
    //     }

    //     &:last-child {
    //       padding-right: 0rem !important;
    //     }
    //   }
    // }
  }

  tbody {
    tr {
      &:hover {
        cursor: pointer;
      }

      td {
        // &:first-child {
        //   padding-left: 0rem !important;
        // }

        // &:last-child {
        //   padding-right: 0rem !important;
        // }

        line-height: 1rem;
        vertical-align: middle;


      }
    }
  }

  &.data-table {
    tr {
      th {
        &:first-child {
          padding-left: 1rem !important;
        }
      }

      td {
        &:first-child {
          padding-left: 1rem !important;
        }
      }
    }
  }
}

.table > :not(caption) > * > * {
  border-bottom-width: 0px;
}

.table-hover > tbody > tr:hover > * {
  color: #262b40 !important;
}

.table-hover > tbody > tr:hover {
  background: #ffffff !important;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.05), 0 10px 10px rgba(0, 0, 0, 0.05);
}

.table-fixed {
  background: white;
  position: sticky;
  top: 0;
}

.order-status > *::before {
  font-family: "Font Awesome 5 Free";
  content: "\f111";
  display: inline-block;
  display: inline-block;
  margin-right: 4px;
  font-size: 7px;
  vertical-align: middle;
}

.fw-filter {
  font-size: 0.85rem !important;
  padding-left: 0.4rem;
  font-weight: 400 !important;
}

.flag-icon-tr {
  background-image: url("https://raw.githubusercontent.com/lipis/flag-icon-css/f4fe454c3d773ee33c0d643430411099beba660f/flags/4x3/tr.svg");
  display: inline-block;
  width: 24px;
  height: 16px;
  margin-right: 5px;
  vertical-align: middle;
}

.filter-clear button,
.filter-date button,
.filter-more button,
.filter-price button,
.filter-publisher button,
.filter-provider button,
.filter-store button,
.filter-search button,
.filter-status button {
  font-size: 0.75rem;
  font-weight: 500;
}

.region-table-container {
  min-width: 150px;
}

.region-table {
  background-color: #f8f9fa;
  font-size: 12px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  text-align: center;
  line-height: 30px;
  color: #97979f;
  display: inline-block;
  margin-left: -10px;
  border: 2px solid #fff;
}

.region-title {
  font-size: 18px;
  font-weight: bolder;
  text-align: left;
}

.region-list ul {
  list-style: none;
  text-align: left;
  padding: 0;
  list-style: none;
  text-align: left;
  padding: 0;
}

.region-table {
  background-color: rgb(242, 245, 253);
  font-size: 15px;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  text-align: center;
  line-height: 40px;
  color: $color-cloudy-blue;
  display: inline-block;
  margin-left: -15px;
  border: 2px solid $color-white;
}

.region-table-count {
  background-color: $color-cloudy-blue;
  font-size: 15px;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  text-align: center;
  line-height: 40px;
  color: $color-white;
  display: inline-block;
  margin-left: -15px;
  margin-right: 10px;
  border: 2px solid $color-white;
}

.btn-tbl {
  width: 140px;
  height: 44px;
  border-radius: 27pt;
  border: 1px $color-pale-grey;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  background-color: transparent;
}

.add-card {
  background-color: $color-pale-grey;
  color: $color-sapphire;
  &:hover {
    color: $white-hover;
    background-color: $color-sapphire;
  }
}

.view-card {
  color: $color-soft-blue;
}

.table-width-100 {
  table-layout: fixed;
}

.table-total-count {
  text-align: end;
  color: $color-dark-slate-blue;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  margin: 0px;
  opacity: 0.7;
}

.card-table {
  thead {
    tr {
      th {
        p {
          margin: 0px;
          color: $color-light-navy;
          font-family: "Poppins", sans-serif;
          font-size: 15px;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        p {
          margin: 0px;
          color: $color-cloudy-blue;
          font-family: "Poppins", sans-serif;
          font-size: 15px;
          font-weight: 400;
        }
      }
    }
  }
}

.column {
  float: center;
  width: 32%;
  padding-top: 1rem;

  @include mobile-only {
    width: 100%;
  }

  @include tablet-only {
    width: 100%;
  }

  @include desktop-only {
    width: 50%;
  }

  @include desktop-xl-only {
    width: 50%;
  }
}

.order-info {
  @include tablet-only {
    width: 100% !important;
  }
  @include desktop-only {
    width: 100% !important;
  }
}

.selected-row{
  background-color: $color-extra-light-blue;
}

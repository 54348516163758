.table-sub-title {
  font-family: "Poppins", sans-serif;
  color: $color-title-blue;
}

.form-check-input {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 0.4rem !important;
  margin: 0;
  border-color: #c6cee3;
  background-color: #fff;

  &:hover,
  &:focus {
    border-color: #c6cee3;
  }

  &:checked {
    background-image: url("../images/icons/icon-checkmark.svg") !important;
    background-size: 50%;
    background-color: $color-yellowish-green;
    border-color: $color-yellowish-green;
    cursor: pointer;
  }
}

$cloudy-blue: rgb(170, 182, 214);

.create-update-promotion-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px 200px 0px 200px;

  @media (max-width: 1940px) {
    padding: 0px 50px 0px 50px;
  }

  @media (max-width: 1440px) {
    padding: 0px;
  }
}

.set-price-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.blank-page {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  .blank-page-container {
    text-align: center;
    .blank-page-description {
      margin-top: 25px;
      color: $cloudy-blue;
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      font-weight: normal;
    }
  }
}

.select-region-cards-container {
  align-items: center;
  max-height: 400px;
  width: 800px;
  overflow: auto;

  box-shadow: none;
  border-color: rgba(198, 206, 227, 0.2) !important;
  margin: 0;
  border: solid;
  border-width: 1.5px;
  border-radius: 25px !important;
  padding: 36px 40px 38px 50px;
}

.content__container {
  padding: 0 1.5rem 1rem 1.5rem;
  @include tablet {
    padding-left: 20px;
    padding: 0 3rem 1rem 3rem;
  }
  @include desktop {
    padding-left: 20px;
    padding: 0 6rem 1rem 6rem;
  }
}

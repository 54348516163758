@import "_variables";

.steps-navigator {
  @include mobile-only {
    display: none !important;
  }

  @include tablet {
    display: flex !important;
  }
}

.steps {
  position: relative;
  display: flex;
  align-items: center;
  color: $color-light-blue-grey;
}

.steps-selected {
  color: $color-white;
}

.steps-completed {
  color: $color-yellowish-green;
}

.steps-text {
  justify-content: center;
  border-radius: 50%;
  transition: width 5s;
  transition-timing-function: ease-in-out;
  height: 3rem;
  width: 3rem;
  border: 2px solid $color-light-blue-grey;
  background-color: $color-white;
  position: relative;
  display: flex;
  align-items: center;
}

.steps-text-selected {
  color: $color-yellowish-green;
  border: 2px solid $color-yellowish-green;
}

.steps-text-incompleted,
.steps-text-done {
  background-color: $color-white;
  border: 2px solid $color-yellowish-green;
  color: $color-yellowish-green;
}

.steps-title {
  position: absolute;
  align-items: center;
  top: 0px;
  margin-left: -2.5rem;
  margin-top: 4rem;
  width: 8rem;
  font-weight: normal;
  font-size: 0.75rem;
  text-align: center;
}

.steps-title-selected {
  color: $color-title-blue;
  font-weight: 600;
}

.steps-title-incompleted {
  color: $color-light-blue-grey;
  font-weight: 400;
}

.steps-title-done {
  color: $color-title-blue;
  font-weight: 500;
}

.steps-line {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  border-top: 2px solid $color-light-blue-grey;
  bottom: -22px;
  transition: width 5s;
  transition-timing-function: ease-in-out;
}

.steps-line-selected {
  border-top: 2px dashed $color-yellowish-green;
  bottom: -22px;
}

.steps-line-done {
  border-top: 2px solid $color-yellowish-green;
}

.stepper-footer {
  bottom: 0;
  width: 60%;
  margin-bottom: 30px;
}

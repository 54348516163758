.credit-limit-subtitle {
  margin: 0;
  color: $marine;
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.divider {
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #e5e9f8;
}

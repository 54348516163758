.profile-container {
  position: relative;
  width: 50%;
  margin: auto;
}

.profile-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100px;
  height: 100px;
  opacity: 0;
  transition: 0.5s ease;
  border-radius: 50%;
  background-color: #646566;
}

.profile-container:hover .profile-overlay {
  opacity: 0.8;
}

.profile-text {
  color: white;
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

#profileImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #512da8;
  font-size: 35px;
  color: #fff;
  text-align: center;
  line-height: 100px;
}

#profileSmallImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #512da8;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 50px;
}

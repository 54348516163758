.promotion-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 80px;
  font-family: "Poppins", sans-serif;
  color: $color-title-blue;

  .promotion-product {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 40px;

    label {
      margin: 0;
      font-weight: 18px;
    }

    p {
      margin: 0;
      font-weight: lighter;
      font-size: 16px;
    }
  }
}

$marine: rgb(8, 34, 105);
$marine-focus: rgba(8, 34, 105, 0.65);
$border-color: rgba(245, 247, 255, 0.5);

.promotion-box-container {
  box-sizing: border-box;
  min-width: 600px;
  height: 350px;
  border-radius: 25px;
  border: 1.5px solid #c6cee3;
  margin: 0px auto;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow-y: auto;

  .left-column {
    color: #16254e;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    line-height: 30px;
    margin-left: 40px;
    font-weight: bold;
    width: 250px;
    word-wrap: break-word;
  }

  .right-column {
    color: #16254e;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    line-height: 30px;
    margin-left: 40px;
    margin-right: 40px;
  }
}

.promotion-percentage-price-box {
  box-sizing: border-box;
  max-height: 350px;
  border-radius: 25px;
  border: 1.5px solid #c6cee3;
  margin: 0px auto;
  overflow-y: auto;
  max-width: 650px;
  min-width: auto;

  .table-responsive {
    table {
      tbody {
        tr {
          td {
            &:first-child {
              padding-left: 37px !important;
            }
            span {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

.terms-check-label {
  color: #16254e;
  font-size: 14px;
  padding-left: 17px;
  font-weight: 400;
  margin: 0;

  &:hover {
    cursor: pointer;
  }
}

.terms-check {
  margin-top: 20px;
  margin-right: 150px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

/* Loader */

.loader {
  width: 89%;
  height: 100%;
  display: flex;
  margin: 0 0 2em;
  text-align: center;
  vertical-align: top;
  position: absolute;
  align-items: center;
  justify-content: center;

  svg {
    enable-background: new 0 0 50 50;

    path,
    rect {
      fill: $color-primary;
    }
  }
}

.checkboxes_permission {
    padding-top: 26px;
    ul {
      li {

        margin: 0;
        padding: 0;
        list-style: none;
      }
    }
    li {
      display: inline-block;
      // &:nth-child(6n){
      // };
    }
    @include mobile-only {
      li {
        width: 50%;
      }
    }
    @include tablet {
      li {
        width: 50%;
      }
    }
    @include desktop-xl {
      li {
        max-width: 150px;
        margin: 0px 23px 10px 23px;
      }
    }
  }
  
  .checkboxes_permission {
    .form-check > input[type="checkbox"]:checked + label {
      color: #b3d515;
      opacity: 1;
    }
  
    .form-check > input[type="checkbox"] + label {
      color: #16254e;
      opacity: 1;
      padding-left: 10px;
    }
  }

  .permission_title{
    min-width: 134px;
    margin: 26px;
    p{
      color: #aab6d6;
    }
  }

  .permission_content{
    width: 100%;
  }

  .permission-background{
    background-color: #f4f6ff;
  }

  .permission_groups{
    min-width: 210px;
  }

  .permission-button {
    background-color: $yellowish-green;
    color: $white;
    border-radius: 6px;
    border: none;
    width: fit-content;
    padding: 16px 41px 16px 41px;
    font-family: "Poppins", sans-serif;
    &:hover {
      color: $white-hover;
      background-color: $yellowish-green-hover;
    }
  }

   .form-check-input-reverse{
    display: inherit !important;
    margin-left: 15px !important;
    }
    .permission-select-all{
      padding: 14px 18px 14px 18px;
    }

    .permission_search{
      padding: 0px;
      padding-bottom: 43px;
      input{
        height: 60px;
        border-radius: 6px !important;

      }

      .form-search{
        top: 36px;
        i{
          background-color: transparent;
          padding: 10px;
        }
      }
    }

    .permission-container{
      margin-top: 55px;
    }

    .permission-thrash{
      padding: 14px 18px 14px 18px;
      height: 53px;
      background-color: #f4f6ff;
      cursor: pointer;
    }

    .edit-group-name{
      cursor: pointer;
    }

    .permission-clear-all{
      color: #fa758a;
    }

    .list-active{
      background-color: #f5f5f59e;
    }

    .permission-list-item{
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }


    .disabledbutton {
      pointer-events: none;
      opacity: 0.4;
  }
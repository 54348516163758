.Toastify__toast-body {
  color: white !important;
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  padding: 15px 0 15px 0;
  width: 100%;
  font-weight: 400;
  margin-left: 25px !important;
  font-family: "Poppins", sans-serif;
}

//Revoked
.Toastify__toast--warning {
  opacity: 0.9;
  border-radius: 20px !important;
  background: rgb(239, 87, 111) !important;

  &::before {
    content: url("../images/toast/revoke.png");
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 14px;
  }
}

//Error
.Toastify__toast--error {
  opacity: 0.9;
  border-radius: 20px !important;
  background: rgb(239, 87, 111) !important;

  &::before {
    content: url("../images/toast/error.png");
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 14px;
  }
}

//Success
.Toastify__toast--success {
  opacity: 0.9;
  border-radius: 20px !important;
  background: rgb(179, 213, 21) !important;

  &::before {
    content: url("../images/toast/success.png");
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 16px;
  }
}

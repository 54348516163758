.availables {
  border: 1px solid #c6cee3;
  border-radius: 25px;
  padding: 24px 0px 10px 0px;
  margin: 0px 30px 30px 70px;
  @include mobile-only {
    margin: 0px;
  }
  //   width: 700px;

  .available-item {
    text-align: center;
  }
}
.checkboxes {
  margin-top: 40px;
  ul {
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
  li {
    display: inline-block;
  }
  @include mobile-only {
    li {
      width: 50%;
    }
  }
  @include tablet {
    li {
      width: 50%;
    }
  }
  @include desktop-xl {
    li {
      width: 33.3333%;
    }
  }
}

.checkboxes {
  .form-check > input[type="checkbox"]:checked + label {
    color: #b3d515;
    opacity: 1;
  }

  .form-check > input[type="checkbox"] + label {
    color: #16254e;
    opacity: 1;
    padding-left: 10px;
  }
}

.Select_OS {
  cursor: pointer;
  border: 1px solid #c6cee3;
  border-radius: 15px;
  width: auto;
  max-width: 300px;
  height: 50px;
  margin-top: 20px;
  padding-top: 5px;
  //@debug: 120px;
  img {
    //max-width: 160px;
    @include mobile-only {
      width: 4 0px;
      padding: 0 15px 0 15px;
    }
    @include tablet {
      width: 100px;
      padding: 0px 30px 0px 30px;
    }
  }
}

.selected_os {
  border: solid 3px #b3d515;
}

.operating-system-text {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: bold;
  margin-top: 40px;
}

.available-platform-text {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: bold;
  margin-top: 60px;
}

.comma:not(:first-child) {
  margin-left: -0.3em;
}

.comma:first-child:empty ~ .comma:not(:empty) {
  margin-left: 0;
}

.comma:first-child:empty ~ .comma:not(:empty) ~ .comma:not(:empty) {
  margin-left: -0.3em;
}

.comma:empty {
  display: none;
}

.comma:not(:first-child):before {
  content: ", ";
}

.comma:empty + .comma:not(:empty):before {
  content: "";
}

.comma:not(:empty) ~ .comma:empty + .comma:not(:empty):before {
  content: ", ";
}

.comma:nth-child(2)::before {
  content: "";
}

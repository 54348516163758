/* Sidebar */
@import "_mixins";

.sidebar {
  background: linear-gradient(90deg, #2966fa, #5f7bf7) !important;
  padding-left: 1.5rem;

  @include desktop {
    max-width: 265px !important;
  }

  .nav-item {
    
    position: relative;
    min-height: 3.5rem;
    padding-left: 0.25rem;

    .nav-link-container {
      height: 3.5rem;

      .nav-link {
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9;
        border-radius: 0;
        height: 3.5rem;
        padding: 0.65rem 1.5rem;

        span {
          &.sidebar-icon {
            img {
              max-width: 2rem;
              transition: margin ease-out 0.1s;
            }
          }

          &.sidebar-text {
            font-size: 0.9rem;
            font-weight: 400;
            font-family: "Poppins", sans-serif;
          }

          &.new-icon{
            max-width: none;
            width: 200px;
            transition: margin ease-out 0.1s;
            img {
                width: 50px;
            }
          }
        }


      }
    }



    &.active {
      transition: all ease-out 0.1s;

      &:before {
        width: 40px;
        height: 40px;
        content: "";
        position: absolute;
        background-color: #5979f7;
        display: block;
        right: 0;
        top: -40px;
        z-index: 1;
        border-bottom-right-radius: 30px;
        @include mobile-only {
          border-bottom-right-radius: 0px;
        }
        transition: all ease-out 0.1s;
      }

      &:after {
        width: 40px;
        height: 40px;
        content: "";
        position: absolute;
        background-color: #5979f7;
        display: block;
        right: 0;
        top: 100%;
        z-index: 9;
        border-top-right-radius: 30px;
        @include mobile-only {
          border-top-right-radius: 0px;
        }
        transition: all ease-out 0.1s;
      }

      .nav-link-container {
        &:before {
          width: 40px;
          height: 40px;
          content: "";
          position: absolute;
          background-color: #fff;
          display: block;
          right: 0;
          top: -40px;
        }

        &:after {
          width: 40px;
          height: 40px;
          content: "";
          position: absolute;
          background-color: #fff;
          display: block;
          right: 0;
          top: 100%;
        }

        .nav-link {
          &:before {
            width: 100%;
            height: 100%;
            content: "";
            position: absolute;
            background-color: #fff;
            display: block;
            margin-left: 0;
            padding-right: 10%;
            border-bottom-left-radius: 100px;
            border-top-left-radius: 100px;
          }

          &:after {
            width: 100%;
            height: 100%;
            content: "";
            top: 0;
            position: absolute;
            background-color: #fff;
            display: block;
            color: $color-soft-blue;
            margin-left: 20%;
            padding-right: 20%;
            z-index: -1;
          }

          span {
            color: $color-soft-blue !important;

            &.sidebar-icon {
              padding-left: 1rem;
              margin-right: 0;

              img {
                max-width: 3rem;
                filter: invert(50%) sepia(100%) saturate(360%)
                  hue-rotate(191deg) brightness(86%) contrast(110%);
              }
            }

            &.sidebar-text {
              font-weight: 500;
              font-size: 1rem;
            }
          }
        }
      }
    }


  }
}

.min-mail-area{
  @include desktop {
    min-width: 280px;
    text-align: center;
  }

}
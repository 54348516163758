$dark-slate-blue: rgb(22, 37, 78);
$marine: rgb(8, 34, 105);

.promotion-country-list-table-header {
  font-size: 18px !important;
  font-family: "Poppins", sans-serif;
  color: $marine;
  font-weight: 600 !important;
}

.promotion-country-list-region-header {
  padding-left: 1rem !important;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: $marine;
  font-weight: 500 !important;
}

.promotion-country-list-footer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  h5 {
    display: inline-block;
    padding-left: 20px;
    margin-top: 20px;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    color: $marine;
  }
}
.percentage-sum-content{
  margin: 0px auto;
  box-sizing: border-box;
  max-width: 650px;
}

.percentage-sum {
  display: flex;
  .percentage-sum-p {
    margin-left: auto;
    margin-right: 50px;
    color: black;
    font-weight: bold;
    font-size: larger;
    .success {
      color: green;
    }
    .danger {
      color: red;
    }
  }
}

$marine: rgb(8, 34, 105);
$marine-focus: rgba(8, 34, 105, 0.65);
$border-color: rgba(245, 247, 255, 0.5);

.set-promotion-number-inputs {
  .number-input-quantity {
    margin-top: 15px;
  }

  label {
    margin: 0;
    color: $marine;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }

  input {
    color: $marine;
    border: none;
    border-bottom: inset;
    border-bottom-color: $border-color;
    line-height: 40px;
    &:focus {
      outline: none;
      border-bottom: inset;
      border-bottom-color: $marine-focus;
    }
  }

  .min-quantity, .max-quantity {
    min-width: 500px;
    min-height: 120px;
  }
}

.date-input-label {
  color: $marine;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.1;
}

.date-input-left-label {
  @include mobile-only {
    margin-right: 0px;
  }
  @include desktop {
    margin-right: 70px;
  }
  color: $marine;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}

.date-input-right-label {
  @include mobile-only {
    margin-left: 0px;
  }
  @include desktop {
    margin-left: 70px;
  }
  color: $marine;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}

.time-info {
  color: $marine;
  font-size: 26px;
  font-family: "Poppins", sans-serif;
  font-weight: lighter;
  margin-bottom: 20px;
  opacity: 0.6;
}

.react-datepicker-wrapper {
  text-align: center;
  display: block !important;
  color: $marine;
  font-family: "Poppins", sans-serif;
  font-size: 26px;
  font-weight: lighter;
  width: 250px;

  input {
    border-bottom: inset;
    border-bottom-color: $border-color;
    &::placeholder {
      color: $marine;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }

    &:disabled {
      &::placeholder {
        color: $color-carnation !important;
      }
    }
  }
}

.min-max-description {
  span {
    height: 1000px
  }
}
.report-box-container {
  box-sizing: border-box;
  height: auto;
  border-radius: 25px;
  border: 1.5px solid #c6cee3;
  overflow-y: none;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  @include desktop-xxl {
    width: 70%;
    margin: auto;
  }
  .react-datepicker-wrapper {
    font-size: 22px;
    width: 200px;
    @include desktop {
      font-size: 26px;
      width: 250px;
    }
  }
}

.filter-button-wrapper {
  text-align: end;
  display: none;
  @include desktop-xl {
    display: inline;
  }
}

.top-filter-button-wrapper {
  text-align: end;
  display: inline;
  @include desktop-xl {
    display: none;
  }
}
.charts-padding{
  @include mobile {
    padding: 0 10% 0 10%;
  }
  @include desktop-xl {
    padding: 0 10% 0 0%;
  }
  @include desktop-xxl {
    padding: 0;
  }
  @media screen and (min-width: 1800px) {
    padding: 0 20% 0 20%;
  }
}
.canvas-wrapper {
  width: 100%;
 
  @include desktop-xl {
    width: 85%;
  }
  @include desktop-xxl {
    width: 75%;
  }

  canvas {
    margin-top: -20px;
  }

  h6.title {
    color: #082269;
    font-size: 14px;
    margin-bottom: 25px;
    display: block;
  }
}

.inventory-title {
  h4 {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.1;
    text-align: left;
    color: $marine;
    margin-bottom: 1rem;
  }
}

.inventory-item {
  p {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    text-align: left;
    color: $marine;
    margin-bottom: 0;
  }
}

$marine: rgb(8, 34, 105);
$marine-focus: rgba(8, 34, 105, 0.65);
$border-color: rgba(245, 247, 255, 0.5);

.modal-body-text {
  margin: 0;
  color: $marine;
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.modal-body-demand-text {
  margin: 0;
  color: $marine;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 1rem;
}

.modal-body-demand-text-inactive {
  margin: 0;
  color: #aab6d6;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.modal-body-subtext {
  margin: 0;
  color: $marine;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
}

.modal-order-text {
  color: $marine;
  font-size: 15px;
  text-align: right;
}

.modal-order-details {
  color: $marine;
  font-size: 18px;
  margin-right: 20px;
  margin-top: 10px;
  font-weight: bold;
}

.modal-bottom-details {
  margin-right: 20px;
}

.order-total {
  color: $marine;
  font-size: 16px;
  margin-right: 15px;
  font-weight: bold;
}

.total-text {
  color: $marine;
  font-size: 20px;
  margin-right: 10px;
  margin-right: 25px;
  line-height: normal;
}

.total {
  color: $marine;
  font-size: 40px;
  margin-right: 25px;
  font-weight: bold;
}

.model-content {
  width: 275px;
  height: 550px;
}

.modal-number-inputs {
  .modal-number-input-quantity {
    margin-top: 15px;
  }

  label {
    margin: 0;
    color: $marine;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
  }

  input {
    color: $marine;
    border: none;
    border-bottom: inset;
    border-bottom-color: $border-color;
    line-height: 40px;
    width: 80%;
    margin-right: 50px;
    font-size: 25px;
    &:focus {
      outline: none;
      border-bottom: inset;
      border-bottom-color: $marine-focus;
    }
  }
}

.quantity-box {
  background-color: white;
  box-sizing: border-box;
  min-width: 110px;
  height: 50px;
  border-radius: 10px;
  border: 1.5px solid #f0f3fa;
  padding: 5px 5px;
  color: $marine;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  margin: 15px 0px 15px -50px;
  font-weight: 500;
  padding: 10px 10px 10px 10px;
}

.quantity-text {
  text-align: right;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  padding-right: 10px;
}

.min-max-description {
  color: rgb(242, 127, 127);
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  text-align: left;
  min-height: 20px;
}

.partner-modal-container {
  margin-left: 60px;
  margin-right: 50px;
}

.divider {
  border-top: 1px solid #dfe7fd;
}

.button-back {
  padding-top: 20px;
}

.modal-back-button {
  color: #aab6d6;
  opacity: 1;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: normal;

  &:focus {
    color: #aab6d6 !important;
  }
  &:hover {
    color: #aab6d6 !important;
  }
}

.modal-button {
  color: white;
  margin-left: 150px;
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
  &:hover {
    color: white;
  }
}

.modal-button-tick {
  margin-right: 10px;
}

.new-price-text {
  color: #aab6d6;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: normal;
}

.form-check-input-partner[type="checkbox"] {
  width: 3rem;
  height: 1.5rem;
  border-radius: 0.7rem !important;
  background-image: none !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin-right: 10px;

  &:before {
    width: 1.2rem;
    height: 100%;
    position: absolute;
    content: " ";
    z-index: 9;
    background: #d9dde8;
    border-radius: 50%;
    margin-right: 26px;
  }

  &:checked {
    background-color: $color-accent;
    border-color: $color-accent;
    background-image: none !important;
    cursor: pointer;

    &:before {
      content: none;
    }

    &:after {
      width: 1.2rem;
      height: 100%;
      position: absolute;
      content: " ";
      z-index: 9;
      background: white;
      border-radius: 50%;
      margin-left: 27px;
    }
  }
}

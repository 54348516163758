$white: rgb(255, 255, 255);
$modal-righ-side: rgb(247, 249, 254);
$light-navy: rgb(18, 42, 96);
$marine: rgb(8, 34, 105);
$dark-slate-blue: rgb(22, 37, 78);

#order-modal {
  .modal-content {
    background: transparent;
  }
  .modal-body {
    padding: 0;
    width: 670px;
    min-height: 400px;
    border-radius: 26px;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .modal-left-side {
      padding: 35px 35px 45px 50px;
      width: 424px;
      .order-view-modal-body-text {
        margin: 0;
        color: $marine;
        font-size: 16px;
        font-family: "Poppins", sans-serif;
        margin-top: 18px;
      }
      .price {
        margin-top: 36px;
        label {
          color: $light-navy;
          font-size: 14px;
          font-family: "Poppins", sans-serif;
          margin: 0px;
        }
        .total {
          color: $marine;
          font-size: 34px;
          font-family: "Poppins", sans-serif;
          font-weight: bolder;
          line-height: 40px;
          width: 300px;
          .no-demand-label {
            label {
              color: $cloudy-blue;
              font-size: 14px;
              font-family: "Poppins", sans-serif;
              font-weight: 500;
              margin-bottom: 30px;
            }
          }
        }
        .multiply {
          color: $marine;
          font-size: 16px;
          font-family: "Poppins", sans-serif;
          font-weight: bolder;
        }
      }
      .form-switch {
        margin-top: 15px;
        label {
          margin: 0;
          padding-top: 3px;
          color: $dark-slate-blue;
          font-size: 14px;
          font-family: "Poppins", sans-serif;
          font-weight: lighter;
        }
      }
      .number-inputs {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .number-input-price {
          max-width: 120px;
          margin: 0px;
          margin-left: 15px;
          input {
            font-size: 24px;
          }
        }
        .number-input-quantity {
          max-width: 120px;
          margin: 0;
          input {
            font-size: 24px;
          }
        }
      }
      .min-max-description {
        color: rgb(242, 127, 127);
        font-size: 12px;
        font-family: "Poppins", sans-serif;
        text-align: left;
        min-height: 20px;
      }
      .order-modal-buttons {
        display: flex;
        justify-content: center;
        flex-direction: row;
      }
    }
    .modal-right-side {
      padding: 35px 35px 45px 50px;
      width: 246px;
      background-color: $modal-righ-side;
      margin: 0px !important;
      border-top-right-radius: 26px;
      border-bottom-right-radius: 26px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .completion-time {
        color: $cloudy-blue;
        font-size: 13px;
        font-family: "Poppins", sans-serif;
        text-align: end;
      }
      .price1 {
        label {
          margin-bottom: 5px;
          color: $light-navy;
          font-size: 14px;
          font-family: "Poppins", sans-serif;
          font-weight: bolder;
        }
        .total {
          color: $marine;
          font-size: 34px;
          font-family: "Poppins", sans-serif;
          font-weight: bolder;
          line-height: 40px;
          width: 300px;
        }
        .multiply {
          color: $marine;
          font-size: 16px;
          font-family: "Poppins", sans-serif;
          font-weight: bolder;
        }
        .no-demand-label {
          label {
            color: $cloudy-blue;
            font-size: 14px;
            font-family: "Poppins", sans-serif;
            font-weight: 500;
          }
        }
      }
      .price2 {
        label {
          margin-bottom: 5px;
          color: $light-navy;
          font-size: 14px;
          font-family: "Poppins", sans-serif;
          font-weight: bolder;
        }
        .total {
          color: $marine;
          font-size: 34px;
          font-family: "Poppins", sans-serif;
          font-weight: bolder;
          line-height: 40px;
          width: 300px;
        }
        .multiply {
          color: $marine;
          font-size: 16px;
          font-family: "Poppins", sans-serif;
          font-weight: bolder;
        }
        .no-demand-label {
          label {
            color: $cloudy-blue;
            font-size: 14px;
            font-family: "Poppins", sans-serif;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.order-confirm-modal {
  border-radius: 25px;
  padding: 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 535px;
  .order-confirm-icon {
    margin-left: 50px;
    margin-top: -50px;
  }
  .order-confirm-title {
    padding: 0 100px;
    font-family: "Poppins", sans-serif;
    font-size: 26px;
    color: $marine;
  }
  .order-confirm-description {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: $marine;
  }
  .order-item-detail-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    label {
      color: $marine;
      font-size: 18px;
      font-family: "Poppins", sans-serif;
    }
    span {
      color: $cloudy-blue;
      font-size: 18px;
      font-family: "Poppins", sans-serif;
    }
  }
  .order-item-detail-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    label {
      color: $marine;
      font-size: 18px;
      font-family: "Poppins", sans-serif;
    }
    span {
      color: $cloudy-blue;
      font-size: 16px;
      font-family: "Poppins", sans-serif;
      font-style: italic;
      max-height: 300px;
      overflow: scroll;
      overflow-x: hidden;
    }
    span::-webkit-scrollbar {
      display: none;
    }
  }

  .date-input-left-label{
    padding: 0 25px;
    margin: 0;
  }

  .date-input-right-label{
    padding: 0 25px;
    margin: 0;
  }
}

.send-inventory-confirm {
  .order-confirm-title {
    padding: 0 25px;
  }
}

.create-package-modal {
  max-width: 420px;
  .create-package-header {
    font-family: "Mont", sans-serif;
    font-size: 21px;
    color: $marine;
    font-weight: normal;
    margin-top: 10px;
  }
  .create-package-description {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: $light-navy;
    margin-top: 20px;
  }
  .create-package-sub-description {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    color: $cloudy-blue;
    margin-bottom: 30px;
  }
  .modal-number-inputs {
    margin-top: 7px;
    label {
      font-family: "Poppins", sans-serif;
      font-size: 13px;
      color: $marine;
      font-weight: normal;
    }
    input {
      font-family: "Poppins", sans-serif;
      font-size: 29px;
      color: $marine;
      font-weight: lighter;
    }
  }
}

.send-package-modal {
  min-width: 440px;
  .send-package-header {
    font-family: "Mont", sans-serif;
    font-size: 21px;
    color: $marine;
    .send-package-sub-header {
      font-family: "Mont", sans-serif;
      font-size: 14px;
      color: $cloudy-blue;
      font-weight: lighter;
      margin: 0px;
    }
  }
  .send-package-container {
    max-height: 310px;
    overflow: scroll;
    .send-package-item {
      background: white;
      box-shadow: 0px 0px 36px 0px rgba(233, 236, 247, 1);
      margin-bottom: 12px;
      border: none;
      border-radius: 12px;
      height: 68px;
      .send-package-item-id {
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        color: $light-navy;
        font-weight: bold;
      }
      .send-package-item-quantity {
        font-family: "Poppins", sans-serif;
        font-size: 13px;
        color: $light-navy;
      }
      .send-package-done {
        align-self: center;
        font-family: "Poppins", sans-serif;
        font-size: 13px;
        color: $yellowish-green;
        label {
          margin: 0px;
          margin-left: 5px;
        }
      }
      .send-package-button {
        align-self: center;
      }
    }
  }
}

.region-modal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: unquote("calc(100vh - 20px)");
  // 20px offset for the 10px margin on either side

  .modal-content {
    width: 100%;
  }
}

.order-view-details {
  padding-top: 30px;
}

.upcoming-promotions-partners-model {
}

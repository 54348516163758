.filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  @include tablet {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0px;
  }
}

.filter-date .btn,
.filter-more .btn,
.filter-price .btn,
.filter-publisher .btn,
.filter-provider .btn,
.filter-store .btn,
.filter-status .btn,
.filter-product .btn {
  font-size: 0.85rem;
  font-weight: 500;
  max-width: 100%;
  height: 50px;
  border-radius: 0.4rem;
  padding: 1rem 3rem 1rem 1.4rem !important;
  background-color: $color-pale-grey;
  color: $color-cloudy-blue;
  margin-bottom: 42px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  display: flex;
  flex-basis: auto;
  flex-direction: row;
  justify-content: space-between;

  span {
    &.text {
      white-space: break-spaces;
      text-align: left;
      min-width: 95%;
    }
  }

  @include tablet {
    width: 100px;
  }
  @include desktop {
    width: 120px;
  }

  @include desktop-xl {
    width: 150px;
  }

  &:hover {
    color: $color-sapphire;
  }

  &:after {
    content: " ";
    position: absolute;
    display: block;
    width: 1rem;
    height: 100%;
    top: 0;
    right: 0.5rem;
    border: none;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.4;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.81' height='12.59' viewBox='0 0 6.81 12.59'%3E%3Cg id='Group_4494' data-name='Group 4494' transform='translate(-558 -236)'%3E%3Cpath id='Path_6881' data-name='Path 6881' d='M680.354,386.082l1.683-1.679.964-1.038.967,1.042,1.674,1.675.763-.759-3.4-3.4-3.4,3.4Z' transform='translate(-121.595 -145.918)' fill='%235776bb'/%3E%3Cpath id='Path_6882' data-name='Path 6882' d='M680.354,386.082l1.683-1.679.964-1.038.967,1.042,1.674,1.675.763-.759-3.4-3.4-3.4,3.4Z' transform='translate(1244.404 630.508) rotate(-180)' fill='%235776bb'/%3E%3C/g%3E%3C/svg%3E%0A");
  }

  &.active {
    &:after {
      opacity: 1;
    }
  }

  &.more {
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.502' height='18.076' viewBox='0 0 10.502 18.076'%3E%3Cg id='filter' transform='translate(26.461 -7.92) rotate(90)'%3E%3Cpath id='Path_4537' data-name='Path 4537' d='M81.636,210.382H79.955a2.221,2.221,0,0,0-4.211,0H64.974a.707.707,0,0,0,0,1.414H75.743a2.221,2.221,0,0,0,4.212,0h1.681a.707.707,0,1,0,0-1.414ZM77.849,211.9a.808.808,0,1,1,.808-.808A.809.809,0,0,1,77.849,211.9Z' transform='translate(-56.347 -192.908)' fill='%23264ea7'/%3E%3Cpath id='Path_4538' data-name='Path 4538' d='M81.636,338.915H73.9a2.221,2.221,0,0,0-4.212,0h-4.71a.707.707,0,0,0,0,1.414h4.71a2.221,2.221,0,0,0,4.212,0h7.74a.707.707,0,1,0,0-1.414Zm-9.846,1.515a.808.808,0,1,1,.808-.808A.809.809,0,0,1,71.79,340.429Z' transform='translate(-56.347 -315.382)' fill='%23264ea7'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
}

.filter-partner-select-box .btn {
  background-color: #577df7 !important;
  color: $white !important;
  &:hover {
    background-color: #3960d9 !important;
  }
  &:after {
    opacity: 1;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.81' height='12.59' viewBox='0 0 6.81 12.59'%3E%3Cpath id='Path_6499' data-name='Path 6499' d='M680.354,386.082l1.683-1.679.964-1.038.967,1.042,1.674,1.675.763-.759-3.4-3.4-3.4,3.4Z' transform='translate(-679.595 -381.918)' fill='%23fff'/%3E%3Cpath id='Path_6500' data-name='Path 6500' d='M680.354,386.082l1.683-1.679.964-1.038.967,1.042,1.674,1.675.763-.759-3.4-3.4-3.4,3.4Z' transform='translate(686.404 394.508) rotate(-180)' fill='%23fff'/%3E%3C/svg%3E%0A");
  }
}

.create-package-modal {
  .filter-partner-select-box {
    .btn {
      margin-top: 10px !important;
      margin-bottom: 20px !important;
    }
    .dropdown-menu .partner-filter-list {
      max-height: 150px !important;
    }
  }
  .min-max-description,
  .create-package-sub-description {
    margin-bottom: 10px !important;
    min-height: 0;
  }
}

.search-icon {
  color: $color-sapphire;
  opacity: 0.4;
}

.badge {
  color: $color-sapphire;
  background-color: $color-ight-periwinkle;
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 50%;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.1rem;
  top: 0.7rem;
  opacity: 1;

  &:empty {
    display: block;
    opacity: 0;
  }
}

.price-input {
  width: 80px;
  margin-bottom: 10px;

  > input {
    max-width: 70%;
    border: none;
    color: $color-marine;
    opacity: 0.2;
  }
}

.price-button {
  border-radius: 8pt;
  background-color: $color-pale-grey;
  border: none;
  margin-top: 10px;
  display: block;
  width: 35pt;
  height: 35pt;
}

.filter-clear {
  > div {
    > div {
      > a {
        color: $color-carnation;
        font-weight: 10px;
        text-decoration: none;
        margin-bottom: 17px;
        @include mobile-only {
          margin-top: 17px;
        }

        &:hover {
          color: #c33b50;
        }
      }
    }
  }
}

// @include tablet {
//   .filter-clear-desktop {
//     display: none;
//   }
//   .filter-clear-tablet {
//     display: inline;
//   }
// }

.filter-clear-desktop {
  display: none;
  @include desktop {
    display: inline;
    margin-bottom: 20px;
  }
}
.filter-clear-tablet {
  display: inline;
  @include desktop {
    display: none;
  }
}

.form-check-input {
  display: block !important;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 0.4rem !important;
  margin: auto;
  border-color: #f0f3fa;
  background-color: #fff;

  &:hover,
  &:focus {
    border-color: #f0f3fa;
  }

  &:checked {
    background-image: url("../images/icons/icon-checkmark.svg") !important;
    background-size: 50%;
    background-color: $color-yellowish-green;
    border-color: $color-yellowish-green;
    cursor: pointer;
  }
}

.form-check {
  min-height: 1.5rem;
  padding-left: 2em;
  margin-bottom: 0.425rem;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  display: block;

  .form-check-label {
    &:hover {
      cursor: pointer !important;
    }
  }

  &:hover {
    cursor: pointer !important;
  }

  input[type="radio"] {
    border-radius: 50% !important;

    + label {
      color: $color-dark-slate-blue;
    }

    &:checked {
      + label {
        color: $color-dark-slate-blue;
      }
    }

    &:last-child {
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;

      .form-check-label {
        margin-bottom: 0 !important;
      }
    }
  }

  input[type="checkbox"] {
    &:checked {
      + label {
        color: $color-accent;
        opacity: 1;
      }
    }
  }

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.dropdown-menu {
  margin-top: 1.2rem !important;
  min-width: 15rem;
  box-shadow: -1px -0.5rem 3rem rgba(233, 236, 247, 0.65);

  &:before {
    position: absolute;
    left: 1rem;
    top: -0.6rem;
    width: 0;
    height: 0;
    content: "";
    border-left: 1.2rem solid transparent;
    border-right: 1.2rem solid transparent;
    border-bottom: 1.2rem solid #ffffff;
  }
}

.platform-list {
  max-height: 9.3rem;
}

#publisher-main {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1.25rem 0 1.25rem;
}

#provider-main {
  display: flex;
  flex-wrap: wrap;
}

.publisher-tag {
  color: $color-blue-grey;
  background-color: $color-pale-grey;
  font-size: 14px;
  margin: 2px;
  border-radius: 15px;
  cursor: pointer;
}

.provider-tag {
  color: $color-blue-grey;
  background-color: $color-pale-grey;
  font-size: 14px;
  margin: 2px;
  border-radius: 15px;
  cursor: pointer;
}

.finish-Promotion {
  font-family: "Poppins", sans-serif !important;
  color: $color-marine;
  font-size: 1.55rem;
  width: 400px;
  line-height: 2rem;
  font-weight: 500;
  text-align: center;
}

.finish-Promotion-note {
  font-family: "Poppins", sans-serif !important;
  color: $dark-slate-blue;
  font-size: 1.15rem;
  padding: 0 1rem 0 1rem;
  width: 400px;
  line-height: 1.5rem;
  text-align: center;
}

@media (max-width: 767.98px) {
  .filter-date .btn,
  .filter-more .btn,
  .filter-price .btn,
  .filter-publisher .btn,
  .filter-provider .btn,
  .filter-store .btn,
  .filter-status .btn {
    margin: 2px;
    width: 100%;
  }

  .filter-date,
  .filter-more,
  .filter-price,
  .filter-provider,
  .filter-publisher,
  .filter-store,
  .filter-status {
    width: 100%;
    @include mobile-only {
      width: 50%;
    }
  }

  .filter-product {
    width: 100%;
    @include mobile-only {
      width: 100%;
    }
  }

  .filter-search {
    width: 50%;
    @include mobile-only {
      width: 100%;
    }
  }

  .filter-date .btn-group,
  .filter-more .btn-group,
  .filter-price .btn-group,
  .filter-publisher .btn-group,
  .filter-provider .btn-group,
  .filter-store .btn-group,
  .filter-status .btn-group {
    width: 100%;
  }
}

.partner-filter-list {
  max-height: 250px;
  overflow: scroll;
}

.radio-filter {
  max-height: 250px;
}

.allocation-select-product-filter-list {
  max-height: 125px;
  overflow: scroll;
}

.select-game-filter {
  button {
    margin-bottom: 0px !important;
  }
  a {
    margin-bottom: 0px !important;
  }
}

.long-search {
  padding: 0.4rem 0;
}
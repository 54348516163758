.text-draft {
  color: #7e8cb2;
}

.text-paid {
  color: #b3d515;
}

.text-unpaid {
  color: #ef576f;
}

.text-partiallypaid {
  color: #ffa71a;
}

@import "_variables";
@import "_table";
@import "_input";
@import "_flags";
@import "_loader";
@import "_progressbar";
@import "_basket";
@import "_button";
@import "_collapse";
@import "_filter";
@import "_stepper";
@import "_modal";
@import "_numberinput";
@import "_partnermodal.scss";
@import "_setpromotion.scss";
@import "_modal";
@import "_numberinput";
@import "_partnermodal.scss";
@import "_stepper";
@import "_countrylist";
@import "_publishpromotion.scss";
@import "_card";
@import "_header";
@import "_sidebar.scss";
@import "step.osAndPlatform";
@import "_container";
@import "_toast.scss";
@import "_tabs.scss";
@import "_createnewpromotion.scss";
@import "_profile.scss";
@import "_creditLimit.scss";
@import "_invoices.scss";
@import "orderdetail";
@import "_pager";
@import "_report";
@import "_navbar";
@import "./components/selectlist";
@import "./components/datagrid";
@import "./pages/permission";
@import "./pages/vendors";
@import "./pages/companyaccount";
@import "ag-grid-community/dist/styles/ag-grid";
@import "ag-grid-community/dist/styles/ag-theme-balham/sass/ag-theme-balham.scss";

*,
::after,
::before {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html,
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: $color-white;
  font-family: "Poppins", sans-serif;
}

h1 {
  color: $color-dark-blue !important;
  font-size: 1.95rem;
}

.select-promotion .form-check-input:checked {
  background-color: #262b40;
  border-color: #262b40;
}

.cursor-pointer {
  cursor: pointer;
}

.border-collapse {
  border-collapse: separate;
  border-spacing: 0 10px;
}

.d-short tr > .active {
  font-family: "Font Awesome 5 Free";
  content: "\f161";
}

.d-short tr > * {
  color: var(--bs-gray-500) !important;
}

.d-short tr > *:hover {
  color: var(--bs-dark) !important;
}

.d-short tr > th > .table-short-active,
.d-short tr > th > .table-short-active::after {
  color: #262b40 !important;
}

.d-short tr > th > .table-short-active::after {
  content: "\f161";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 0.75rem;
  text-transform: uppercase;
  padding-left: 3px;
  color: inherit;
}

.d-short tr > th > .table-name:hover:after {
  content: "\f161";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 0.75rem;
  text-transform: uppercase;
  padding-left: 3px;
  color: inherit;
}

.d-short tr > th > .table-name:after {
  content: "\f161";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 0.75rem;
  text-transform: uppercase;
  padding-left: 3px;
  color: #f9fafb;
}

.bg-blue-gradient {
  background: rgb(119, 140, 245);
  /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    rgba(119, 140, 245, 1) 0%,
    rgba(90, 126, 253, 1) 50%,
    rgba(58, 111, 255, 1) 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    rgba(119, 140, 245, 1) 0%,
    rgba(90, 126, 253, 1) 50%,
    rgba(58, 111, 255, 1) 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    rgba(119, 140, 245, 1) 0%,
    rgba(90, 126, 253, 1) 50%,
    rgba(58, 111, 255, 1) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#778cf5', endColorstr='#3a6fff', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

.bg-merkur-m {
  background-image: url(../../assets/images/merkur-mark.svg);
  background-size: cover;
}

/*Turan*/
.react-datepicker-wrapper {
  display: block !important;
}

.basket-count:after {
  content: attr(value);
  font-size: 11px;
  background: #9dd244;
  color: white;
  border-radius: 100%;
  display: inline-block;
  padding: 3px;
  position: relative;
  right: -15px;
  top: 12px;
  opacity: 0.9;
  width: 20px;
  height: 20px;
  font-family: var(--bs-font-sans-serif);
}

.rounded-1 {
  border-radius: 0.4rem !important;
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
  font-size: 0.85rem !important;
}

.order-collapse-card {
  &:hover {
    cursor: pointer;
  }
}

.dashboard-title-text {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  color: $marine;
}

.go-back {
  display: -webkit-inline-box;
}

.vertical-seperator {
  h1 {
    font-size: 42px;
    color: #0d225a;
    margin-bottom: 0px;
    font-weight: 300;
    margin-left: 15px;
  }
}

.monthly-profit {
  .order-history {
    font-size: 20px;
    color: rgb(8, 34, 105);
    font-weight: 400;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

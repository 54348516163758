@import "../variables";

.select-list .btn{
    font-weight: 500;
    color: $color-cloudy-blue;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    display: flex;
    flex-basis: auto;
    flex-direction: row;
    width: 100%;
    span {
      &.text {
        white-space: break-spaces;
        text-align: left;
        min-width: 95%;
      }
    }
  
    @include tablet {
      width: 150px;
    }
  
    &:hover {
      color: $color-sapphire;
    }
  
    &:after {
      content: " ";
      position: absolute;
      display: block;
      width: 1rem;
      height: 100%;
      top: 0;
      right: 0.5rem;
      border: none;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0.4;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.81' height='12.59' viewBox='0 0 6.81 12.59'%3E%3Cg id='Group_4494' data-name='Group 4494' transform='translate(-558 -236)'%3E%3Cpath id='Path_6881' data-name='Path 6881' d='M680.354,386.082l1.683-1.679.964-1.038.967,1.042,1.674,1.675.763-.759-3.4-3.4-3.4,3.4Z' transform='translate(-121.595 -145.918)' fill='%235776bb'/%3E%3Cpath id='Path_6882' data-name='Path 6882' d='M680.354,386.082l1.683-1.679.964-1.038.967,1.042,1.674,1.675.763-.759-3.4-3.4-3.4,3.4Z' transform='translate(1244.404 630.508) rotate(-180)' fill='%235776bb'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
  
    &.active {
      &:after {
        opacity: 1;
      }
    }
  
    &.more {
      &:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.502' height='18.076' viewBox='0 0 10.502 18.076'%3E%3Cg id='filter' transform='translate(26.461 -7.92) rotate(90)'%3E%3Cpath id='Path_4537' data-name='Path 4537' d='M81.636,210.382H79.955a2.221,2.221,0,0,0-4.211,0H64.974a.707.707,0,0,0,0,1.414H75.743a2.221,2.221,0,0,0,4.212,0h1.681a.707.707,0,1,0,0-1.414ZM77.849,211.9a.808.808,0,1,1,.808-.808A.809.809,0,0,1,77.849,211.9Z' transform='translate(-56.347 -192.908)' fill='%23264ea7'/%3E%3Cpath id='Path_4538' data-name='Path 4538' d='M81.636,338.915H73.9a2.221,2.221,0,0,0-4.212,0h-4.71a.707.707,0,0,0,0,1.414h4.71a2.221,2.221,0,0,0,4.212,0h7.74a.707.707,0,1,0,0-1.414Zm-9.846,1.515a.808.808,0,1,1,.808-.808A.809.809,0,0,1,71.79,340.429Z' transform='translate(-56.347 -315.382)' fill='%23264ea7'/%3E%3C/g%3E%3C/svg%3E%0A");
      }
    }
  }

  .select-list {
    .btn-group{
      .dropdown-menu{
        margin-top: 0px !important;
        width: 100%;
      }
    }
  }
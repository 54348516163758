.progress-wrapper {
  .progress {
    height: 1.4rem !important;
    background-color: #eff2fc !important;
    box-shadow: none !important;

    .progress-bar {
      //border-radius: 1rem;
    }
  }

  .progress-info {
    .progress-label {
      .text-primary {
        font-size: 2rem;
        font-weight: 700;
        color: $color-dark-blue !important;
      }
    }

    .progress-percentage {
      span {
        color: $color-dark-blue !important;
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }

  .progress-variants {
    .row {
      .label {
        font-weight: 700;
        color: $color-dark-blue !important;
      }

      .variant {
        color: $color-dark-blue !important;
      }
    }
  }
}

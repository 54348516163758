.order-detail {
  flex-direction: column;
  @include desktop-xxl {
    flex-direction: row;
  }
}

.order-details-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 50px 30px 70px;
  @include tablet-only {
    padding: 0px !important;
    margin-right: 50px;
    width: 100%;
  }
  margin-right: 30px;
  width: 100%;
  border-radius: 26px;
}

.order-detail-summary {
  padding: 40px 50px 30px 70px;
  margin-right: 30px;
  width: 100%;
  border-radius: 26px;
}

.order-details-summary-left {
  margin-right: 10px;
}

.order-details-quantity {
  border-radius: 26px;
  @include desktop-xxl {
    width: 60%;
  }
  @include tablet {
    width: 100%;
  }
  width: auto;
}

.order-detail-up-down-arrow {
  margin-top: 12px;
  margin-right: 5px;
  width: 12px;
  height: 12px;
}

.down-text-color {
  color: $carnation !important;
}

.equal-text-color {
  color: $cloudy-blue !important;
}

.up-text-color {
  color: $yellowish-green !important;
}

.order-detail-table-no-item {
  color: $cloudy-blue;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: lighter;
  margin-left: 16px;
  margin-top: 10px;
}

.order-history-quantity-status {
  font-size: 26px;
  font-family: "Poppins", sans-serif;
  font-weight: lighter;
}

.yellowish-green {
  color: $yellowish-green;
}
.mango {
  color: $mango;
}
.carnation {
  color: $carnation;
}
.dark-slate-blue {
  color: $dark-slate-blue;
}
.cloudy-blue {
  color: $cloudy-blue;
}

.order-history-quantity-price {
  color: $yellowish-green;
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  img {
    margin-bottom: 7px;
    margin-right: 5px;
  }
}

.order-history-voidu-profit {
  margin-top: 10px;
  margin-right: 10px;
  color: $cloudy-blue;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.order-history-voidu-profit-text {
  color: $cloudy-blue;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 300;
  margin-top: 17px;
  margin-right: 3px;
}

.order-history-voidu-price {
  margin-top: 10px;
  margin-right: 10px;
  color: $cloudy-blue;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.order-history-voidu-price-text {
  font-size: 13px;
  font-weight: 300;
  margin-top: 17px;
  margin-right: 3px;
  font-family: "Poppins", sans-serif;
}

.order-detail-completed-message {
  color: $yellowish-green;
}

.order-detail-out-of-stock-message {
  color: $marine;
}

.order-detail-precompleted-message {
  color: $mango;
}

.order-detail-error-message {
  color: $carnation;
}

.order-detail-message {
  width: fit-content;
}

.dashed-line {
  border-bottom: dashed;
  border-color: rgba(170, 182, 214, 0.3);
}

.order-detail-table {
  border-radius: 26px;
  .order-detail-table-header {
    cursor: pointer;
    color: $marine;
    font-size: 14px;
  }
}

.strikediag {
  position: relative;
}
.strikediag:before {
  position: absolute;
  content: "";
  left: 0;
  top: 35%;
  right: 0;
  border-top: 1px solid;
  border-color: inherit;
  transform: rotate(-25deg);
}

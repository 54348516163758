@import "_variables";
$marine: rgb(8, 34, 105);
$marine-focus: rgba(8, 34, 105, 0.65);
$border-color: rgba(245, 247, 255, 0.5);

.error-input-bad{
  border-bottom-color: red;
}

.number-input {
  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: $cloudy-blue !important;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $cloudy-blue !important;
    opacity: 1;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $cloudy-blue !important;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $cloudy-blue !important;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $cloudy-blue !important;
  }

  &::placeholder {
    /* Most modern browsers support this now. */
    color: $cloudy-blue !important;
  }
}

.number-inputs {
  .number-input-quantity {
    margin-top: 15px;
  }

  label {
    margin: 0;
    color: $marine;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }

  input {
    color: $marine;
    font-size: 29px;
    font-family: "Poppins", sans-serif;
    border: none;
    border-bottom: inset;
    border-bottom-color: $border-color;
    line-height: 40px;
    &:focus {
      outline: none;
      border-bottom: inset;
      border-bottom-color: $marine-focus;
    }
  }
}

.number-promotion-inputs {
  label {
    margin: 0;
  }
  input {
    width: 50%;
    text-align: right;
    color: $marine;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    border: none;
    border-bottom: inset;
    border-bottom-color: $border-color;
    line-height: 24px;
    margin-top: -10px;
    &:focus {
      line-height: 24px;
      outline: none;
      border-bottom: inset;
      border-bottom-color: $marine-focus;
    }
  }
  span {
    // margin-left: -170px;
    color: $marine;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
  }
}

.number-inputs-set-price {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin-top: 40px;
  margin-left: 10%;

  label {
    width: 50%;
    margin: 0;
    color: $marine;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }

  input {
    width: 50%;
    text-align: center;
    color: $marine;
    font-size: 29px;
    font-family: "Poppins", sans-serif;
    border: none;
    border-bottom: inset;
    border-bottom-color: $border-color;
    line-height: 24px;
    &:focus {
      outline: none;
      border-bottom: inset;
      border-bottom-color: $marine-focus;
    }
  }
}

.set-promotion-number-inputs {
  text-align: center;
  input {
    text-align: center;
  }
}
.bulk-order-number-inputs {
  text-align: center;
  input {
    text-align: center;
    color: $marine;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    text-align: center;
    outline: none;
    border: none;
    border-bottom: inset;
    border-bottom-color: $marine-focus;
  }
  &.quantity {
    min-width: 250px;
  }
}

.error-input {
  input {
    border-bottom-color: $carnation !important;
  }
}

#promotion-detail {
  font-size: 29px;
  font-family: "Poppins", sans-serif;
  font-weight: lighter;
  width: 180px;
}

.number-input-percentage {
  color: gray;
  font-size: 20px;
}

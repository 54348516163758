$breakpoints: (
  xs: 320px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

@mixin mobile {
  @media screen and (min-width: 0) {
    @content;
  }
}

@mixin mobile-only {
  @media screen and (min-width: 0) and (max-width: map-get($breakpoints, md)) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: map-get($breakpoints, md)) {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: map-get($breakpoints, md)) and (max-width: map-get($breakpoints, lg)) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: map-get($breakpoints, lg)) {
    @content;
  }
}
@mixin desktop-only {
  @media screen and (min-width: map-get($breakpoints, lg)) and (max-width: map-get($breakpoints, xl)) {
    @content;
  }
}

@mixin desktop-xl {
  @media screen and (min-width: map-get($breakpoints, xl)) {
    @content;
  }
}

@mixin desktop-xl-only {
  @media screen and (min-width: map-get($breakpoints, xl)) and (max-width: map-get($breakpoints, xxl)) {
    @content;
  }
}

@mixin desktop-xxl {
  @media screen and (min-width: map-get($breakpoints, xxl)) {
    @content;
  }
}

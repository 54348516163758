@import "variables";
.total-result{
  .total-result-title{
    padding: 10px;
    text-align: center;
    width: 200px;
    color: $color-light-blue-grey;
  }


    select{
      background-color: $color-ice-blue;
      border-radius: 8px;
      padding: 9px 20px 9px 20px;
      border: 1px solid $color-ice-blue;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.276' height='5.345' viewBox='0 0 9.276 5.345'%3E%3Cg data-name='Group 4561'%3E%3Cpath data-name='Line 408' transform='translate(.707 .707)' style='fill:none;stroke:%234b66a2;stroke-linecap:round' d='m0 0 3.931 3.931'/%3E%3Cpath data-name='Line 409' transform='translate(4.638 .707)' style='fill:none;stroke:%234b66a2;stroke-linecap:round' d='M3.931 0 0 3.931'/%3E%3C/g%3E%3C/svg%3E%0A");      color: #4b66a2;
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      background-size: 10px 12px;
      appearance: none;
      min-width: 70px;
    }
  }



.pagination {
  a {
    color: $color-light-blue-grey !important;
  }
  .active {
    background-color: $color-ice-blue;
    border-radius: 8px;
    border: 1px solid $color-ice-blue;
    a {
      color: $color-dusky-blue !important;
      &:hover {
        color: white !important;
      }
    }

    &:hover {
      border-radius: 8px;
      border: 1px solid $color-ice-blue;
    }
  }



  .page-item {
    a {
      padding: 10px;
      min-width: 40px;
      text-align: center;
      img{
        padding: 0px;
        width: 55%;
      }
    }

    .page-link {
      border-radius: 8px;
      &:hover {
        background-color: $color-dusky-blue;
        border-color: $color-dusky-blue;
        color: $color-light-blue-grey;
      }
    }
  }
}

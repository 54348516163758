$white: rgb(255, 255, 255);
$mango: rgb(255, 167, 41);
$cloudy-blue: rgb(170, 182, 214);
$dark-slate-blue: rgb(22, 37, 78);
$carnation: rgb(250, 117, 138);
$yellowish-green: rgb(179, 213, 21);
$history-item-circle-line: rgb(223, 231, 253);
$light-navy: rgb(18, 42, 96);
$marine: rgb(8, 34, 105);
$gray: rgb(80, 80, 80);
@import "_mixins";

.card {
  background: white;
  box-shadow: 0px 0px 36px 0px rgba(233, 236, 247, 1);
  margin-bottom: 16px;
  border: none;
}
.collapse-card-header {
  margin: 32px 24px;
  // max-height: 70px;
  // padding: 1.25rem 1.5rem;
  .header-name {
    color: rgb(71, 89, 137);
    line-height: 18px;
    font-family: "Poppins", sans-serif;
    font-size: medium;
    font-weight: bold;
  }

  .header-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .status-container {
      .status-horizontal-container {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 8px;

        .status-vertical-container {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;

          .order-text {
            color: rgb(18, 42, 96);
            line-height: 20pt;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: bold;
          }

          .order-no {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            color: rgb(71, 89, 137);
            line-height: 15pt;
            font-family: "Poppins", sans-serif;
            font-size: 24px;
            margin-bottom: 17px;
            font-weight: bold;
          }

          img {
            border-radius: 30px;
            width: 30px;
            height: 30px;
          }

          .white {
            background-color: $white;
            border: solid;
            border-color: $yellowish-green;
          }

          .mango {
            background-color: $mango;
          }

          .cloudy-blue {
            background-color: $cloudy-blue;
          }

          .dark-slate-blue {
            background-color: $dark-slate-blue;
          }

          .carnation {
            background-color: $carnation;
          }

          .yellowish-green {
            background-color: $yellowish-green;
          }

          .gray {
            background-color: $gray;
          }

          .order-status {
            display: flex;
            justify-content: center;
            flex-direction: column;
            line-height: 14px;
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            margin-left: 10px;
          }
        }

        .order-sub-status {
          line-height: 14px;
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          color: $cloudy-blue;
          margin-top: 12px;
        }
      }
    }

    .price-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      .demand-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-right: 7px;

        label {
          margin: 0;
          color: rgb(8, 34, 105);
          line-height: 21px;
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          text-align: end;
        }

        .multiply {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          color: rgb(8, 34, 105);
          line-height: 23px;
          font-family: "Poppins", sans-serif;
          font-size: 15px;
          font-weight: bold;
        }
      }

      .total-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        color: rgb(8, 34, 105);

        label {
          margin: 0;
          color: rgb(8, 34, 105);
          line-height: 24px;
          font-family: "Poppins", sans-serif;
          font-size: 18px;
          text-align: end;
          font-weight: bold;
        }

        .total-amount {
          color: rgb(8, 34, 105);
          line-height: 44px;
          font-family: "Poppins", sans-serif;
          font-size: 31px;
          font-weight: bold;
          text-align: end;
          white-space: nowrap;
        }
      }

      button {
        background: transparent;
        border: none;
        margin-left: 25px;
      }
    }
  }
}

.order-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-right: 1rem;
  .order-text {
    color: rgb(18, 42, 96);
    line-height: 20pt;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: bold;
  }

  .order-no {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    color: rgb(71, 89, 137);
    line-height: 15pt;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: bold;
    a {
      color: rgb(71, 89, 137);
    }
  }

  .order-date {
    color: rgb(170, 182, 214);
    line-height: 20px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
  }
}

.card-body {
  margin: -25px 8px 45px 25px;
}

.order-buttons {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;

  button {
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
  }

  .form-check {
    margin-bottom: 0;
  }

  .resubmit-container {
    padding-top: 45px;
    width: 400px;
    height: 182px;
    // background-color: $resubmit-container-background;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    flex-direction: row;

    .new-offer {
      label {
        color: $light-navy;
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        font-weight: bolder;
        margin: 0px;
      }

      .total {
        color: $marine;
        font-size: 34px;
        font-family: "Poppins", sans-serif;
        font-weight: bolder;
        line-height: 40px;
      }

      .multiply {
        color: $marine;
        font-size: 16px;
        font-family: "Poppins", sans-serif;
        font-weight: bolder;
      }
    }

    #resubmit-button {
      margin-left: 70px;
      margin-top: 15px;
    }

    #detail-button {
      margin-left: 10px;
      margin-top: 15px;
    }
  }
}
.order-detail-title {
  color: $light-navy;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.order-history {
  margin-top: 10px;
  color: $cloudy-blue;

  .id-knowledge {
    flex-direction: column;
    justify-content: center;

    @include desktop-xl {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .order-history-title {
    color: $light-navy;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
  }

  .order-history-subtitle {
    font-family: "Poppins", sans-serif;

    font-size: 14px;
    margin-bottom: 20px;
    @include desktop-xl-only {
      font-size: 12px;
    }

    a {
      margin-left: 5px;
      border-radius: 70%;
      color: $cloudy-blue;
      &:hover {
      }
      img {
        margin-left: 4px;
        background-color: $cloudy-blue;
        margin-bottom: 3px;
        border-radius: 50%;
      }
    }
    margin-bottom: 10px;
  }
  .order-history-body {
    max-height: 180px;
    overflow: auto;
    padding: 4px;
  }

  .history-item-horizontal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .fa-ellipsis-v:before {
      color: $history-item-circle-line;
      font-size: 12px;
      margin-left: 3px;
    }

    .history-item-vertical {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .fa-circle:before {
        color: $history-item-circle-line;
        align-self: center;
        font-size: 10px;
      }

      .history-item {
        margin-left: 12px;
        font-family: "Poppins", sans-serif;
        size: 14px;
        line-height: 14px;
        margin-top: 5px;
      }
    }
  }
}

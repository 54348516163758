$color-white: #fff !default;
$color-primary: #3a6fff !default;
$color-accent: #b3d515 !default;
$color-saxeblue-medior: #778cf5 !default;
$color-saxeblue: #5a7efd !default;
$color-dark-blue: #0d225a !default;
$color-orange: #ffa71a !default;
$color-red: #fa758a !default;
$color-white: #ffffff !default;
$color-light-gray: #f2f5fd !default;

.bg-primary {
  background-color: $color-primary !important;
}

.bg-secondary {
  background-color: $color-accent !important;
}

$purple: #512da8;
$white: rgb(255, 255, 255);
$white-hover: rgb(245, 245, 245);
$mango: rgb(255, 167, 41);
$mango-hover: rgb(243, 150, 21);
$cloudy-blue: rgb(170, 182, 214);
$cloudy-blue-hover: rgb(141, 151, 179);
$dark-slate-blue: rgb(22, 37, 78);
$dark-slate-blue-hover: rgb(31, 52, 110);
$carnation: rgb(250, 117, 138);
$carnation-hover: rgb(241, 91, 114);
$yellowish-green: rgb(179, 213, 21);
$yellowish-green-hover: rgb(155, 186, 19);
$reject-button: rgb(239, 87, 111);
$reject-button-hover: rgb(221, 55, 80);
$color-pale-grey: rgb(239, 242, 252);
$color-pale-grey-hover: rgb(235, 238, 241);
$marine: rgb(8, 34, 105);
$marine-hover: rgb(5, 20, 61);
$dashboard-see-detail-color: rgb(119, 168, 3);
$filter-dropdown-text: rgb(164, 185, 232);
$filter-dropdown: rgb(239, 242, 252);

$color-saxeblue-darker: #3a6fff !default;
$color-ice-blue: rgb(240, 245, 248) !default;
$color-cloudy-blue: rgb(170, 182, 214) !default;
$color-pale-grey: rgb(239, 242, 252) !default;
$color-pale-grey-hover: rgb(235, 238, 241);
$color-soft-blue: rgb(87, 125, 247) !default;
$color-sapphire: rgb(38, 78, 167) !default;
$color-sapphire-hover: rgb(22, 54, 124) !default;
$color-very-light-pink: rgb(209, 209, 209) !default;
$color-marine: rgb(8, 34, 105) !default;
$color-dark-slate-blue: rgb(22, 37, 78) !default;
$color-light-navy: rgb(18, 42, 96) !default;
$color-carnation: rgb(250, 117, 138) !default;
$color-yellowish-green: rgb(179, 213, 21) !default;
$color-ight-periwinkle: rgb(215, 222, 247) !default;
$color-light-blue-grey: rgb(198, 206, 227) !default;
$color-extra-light-blue: rgba(242, 246, 251, 1) !default;
$color-title-blue: rgb(2, 34, 118) !default;
$color-blue-grey: rgb(126, 140, 178) !default;
$color-dusky-blue: rgb(75, 102, 162) !default;
$color-ice-blue: rgb(240, 245, 248) !default;

$navbar-width: 14rem !default;

.bg-tertiary {
  background-color: $color-saxeblue-medior !important;
}

.bg-dark {
  background-color: $color-dark-blue !important;
}

.bg-gray-200 {
  background-color: #f8f9ff !important;
}

.bg-success {
  background-color: $color-accent !important;
}

.bg-warning {
  background-color: $color-orange !important;
}

.bg-danger {
  background-color: $color-red !important;
}

.bg-white {
  background-color: $color-white !important;
}

.bg-gray {
  background-color: $color-pale-grey !important;
}

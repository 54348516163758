.nav-tabs {
  margin-bottom: 0.5rem;
  border: none;
  @include tablet {
    margin-bottom: 2rem;
  }
  a {
    padding: 25px 20px 20px 15px !important;
    margin-left: -15px !important;
  }
  .nav-link.active {
    padding: 0px;
    background-color: transparent;
    background: url("../images/icons/green-underline-icon.svg") no-repeat;
    background-position: bottom;
    color: $color-marine;
    font-size: 20px;
    line-height: 22px;
    font-family: "Mont", sans-serif;
  }
  .nav-link {
    padding: 0px;
    padding-top: 15px !important;
    background-color: transparent;
    color: $color-cloudy-blue;
    font-size: 20px;
    line-height: 22px;
    font-family: "Mont", sans-serif;
  }
}


